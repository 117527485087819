.resultsDiv {
    display: grid;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: 400;
    background: rgba(0, 0, 0, 0);
    padding: 10px;
    margin-top: 5%;
    text-align: center;
}

p {
    margin: 4px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
}

.name {
    font-size: 40px;
}

@media only screen and (max-width: 600px)  {
   .resultsDiv {
       font-size: 5.5vw;
   }
    
  }

