* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.appContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.appContainer img {
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  object-fit: cover;
}

.overlay-container {
  background-color: rgba(0, 0, 0, 0.591);
  width: 60%;
  margin: 0 auto;
  padding: 0;
  position: absolute;
}

.heading {
  text-align: center;
  padding: 50px;
  margin: 0;
  color: rgb(255, 0, 0);
  font-size: 5vw;
  font-weight: 400;
  font-family: Georgia, 'Times New Roman', Times, serif;
}



.input-container {
  display: block;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
}


.input {
  height: 50px;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.675);
  color: rgb(0, 0, 0);
  font-size: 1.5vw;
  border: 0;
  padding: 5px;
}



.clock {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  margin: 20px;
  color: rgb(255, 255, 255);
}

.result-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

}



.submit {
  background-color: rgba(255, 255, 255, 0.675);
  border: 0;
  height: 50px;
  padding: 5px;
  margin: 5px;
  font-size: small;
}








@media only screen and (max-width: 1000px) {
  .overlay-container {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .overlay-container {
    width: 100%;
  }
}


@media only screen and (max-width: 600px)  {
  .heading {
    font-size: 9vw;
    padding: 20px;
  }

  .input {
    font-size: 2vh;
  }

  .clock {
    font-size: large;
  }
  
}

